<template>
  <navbar :viewsMenu="false" btn-background="bg-gradient-primary" />
  <div
    class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/img/curved-images/curved6.jpg') + ')',
    }"
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-6">
          <h1 class="mt-5 mb-2 text-white">Cambio de Contraseña</h1>
          <p class="text-white text-lead">
            Importante recordar la nueva contraseña, para su próximo uso en el
            inicio de sesión.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="card-body">
            <form role="form" @submit.prevent="handleSubmit">
              <div class="mb-1">
                <soft-input-model
                  id="name"
                  type="password"
                  v-model="form.password"
                  placeholder="Ingrese la nueva contraseña"
                  aria-label="Ingrese la nueva contraseña"
                  :error="v$.password.$error"
                  :errors="v$.password.$errors"
                  :disabled="loading"
                  isAppendFunction
                  iconDir="right"
                  icon="fas fa-eye"
                  iconActive="fas fa-eye-slash"
                />
              </div>

              <div class="mb-1">
                <soft-input-model
                  id="name"
                  type="password"
                  v-model="form.confirmPassword"
                  placeholder="Ingrese la confirmación de la contraseña"
                  aria-label="Ingrese la confirmación de la contraseña"
                  :error="v$.confirmPassword.$error"
                  :errors="v$.confirmPassword.$errors"
                  :disabled="loading"
                />
              </div>

              <div class="text-center">
                <soft-button
                  variant="gradient"
                  color="success"
                  full-width
                  class="my-2"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Enviar
                </soft-button>
              </div>
              <p class="text-sm mt-3 mb-0">
                ¿Deseas volver al inicio de sesión?
                <router-link
                  :to="{ name: 'login' }"
                  class="text-success text-gradient font-weight-bold"
                >
                  Aquí
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";

import SoftButton from "@/components/SoftButton.vue";
import SoftInputModel from "@/components/SoftInputModel.vue";

import { onUnmounted, ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import useVuelidate from "@vuelidate/core";
import { required, helpers, sameAs } from "@vuelidate/validators";

import axiosInstance from "@/services/api";
import Swal from "sweetalert2";

export default {
  name: "PageRestablecer",
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    SoftInputModel,
  },

  setup() {
    const store = useStore();

    store.commit("toggleEveryDisplay");
    store.commit("toggleHideConfig");

    onUnmounted(() => {
      store.commit("toggleEveryDisplay");
      store.commit("toggleHideConfig");
    });

    const route = useRoute();
    const router = useRouter();

    const token = ref(route.params.token || "");

    const form = reactive({
      password: "",
      confirmPassword: "",
    });

    const loading = ref(false);

    const rules = computed(() => ({
      password: {
        required: helpers.withMessage("Contraseña es requerida", required),
        securityPassword: helpers.withMessage(
          "La contraseña debe contener mínimo 8 dígitos, una mayúscula, una minúscula, un número.",
          (value) => {
            if (typeof value === "undefined" || value === null || value === "")
              return true;
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([A-Za-z\d$@$!%*?&]|[^ ]){8,}$/.test(
              value
            );
          }
        ),
      },
      confirmPassword: {
        required: helpers.withMessage(
          "Confirmación de contraseña es requerida",
          required
        ),
        sameAs: helpers.withMessage(
          "Las contraseñas deben coincidir",
          sameAs(form.password)
        ),
      },
    }));

    const v$ = useVuelidate(rules, form);

    const handleSubmit = () => {
      v$.value.$touch();

      if (token.value !== null && token.value !== "") {
        if (!v$.value.$invalid) {
          loading.value = true;

          const payload = {
            newPassword: form.confirmPassword,
            token: token.value,
          };

          axiosInstance
            .post(`/changePassword`, payload)
            .then(({ data }) => {
              let messageNotification;

              const {
                message = `Contraseña actualizada, al aceptar será direccionado al inicio de sesión.`,
                isapp
              } = data.body;

              if (isapp > 0) {
                messageNotification = `Contraseña actualizada, al aceptar, será direccionado al sistema web de Alala. Pero le recordamos que debe ingresar en la APP con la nueva contraseña.`
              } else {
                messageNotification = message;
              }

              Swal.fire({
                allowEnterKey: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                text: messageNotification,
                icon: "success",
                confirmButtonText: "Aceptar",
              }).then(() => {
                v$.value.$reset();

                router.replace({ name: "login" });
              });
            })
            .catch((error) => {
              const { message } = error?.response?.data?.error || {};
              Swal.fire({
                text:
                  message !== null &&
                  message !== "" &&
                  typeof message !== "undefined"
                    ? message
                    : `Inconvenientes en el sistema, por favor inténtelo más tarde.`,
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            })
            .finally(() => (loading.value = false));
        }
      } else {
        Swal.fire({
          text: `Inconvenientes en el sistema, por favor contactar a soporte técnico.`,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    };

    return {
      // Properties
      token,
      form,
      loading,
      v$,

      // Methods
      handleSubmit,
    };
  },
};
</script>
